<template>
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="7"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="">
            <h3>COMISIONES</h3> <small> (LISTA DE VENDEDORES)</small>
          </div>
        </b-col>

      </b-row>

    </div>

    <b-table
      :items="fetchSellers"
      responsive
      :fields="tableColumnsSellers"
      primary-key="id"
      show-empty
      empty-text="No se encontraron registros coincidentes"
      class="position-relative"
    >
      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-edit-icon`"
            icon="EyeIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'seller-commissions', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Ver ventas"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-edit-icon`"
          />
        </div>
      </template>

    </b-table>
<!--    <div class="mx-2 mb-2">-->
<!--      <b-row>-->

<!--        &lt;!&ndash;        <b-col&ndash;&gt;-->
<!--        &lt;!&ndash;          cols="12"&ndash;&gt;-->
<!--        &lt;!&ndash;          sm="6"&ndash;&gt;-->
<!--        &lt;!&ndash;          class="d-flex align-items-center justify-content-center justify-content-sm-start"&ndash;&gt;-->
<!--        &lt;!&ndash;        >&ndash;&gt;-->
<!--        &lt;!&ndash;          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>&ndash;&gt;-->
<!--        &lt;!&ndash;        </b-col>&ndash;&gt;-->
<!--        &lt;!&ndash; Pagination &ndash;&gt;-->
<!--                <b-col-->
<!--                  cols="12"-->
<!--                  sm="12"-->
<!--                  class="d-flex align-items-center justify-content-end justify-content-sm-end"-->
<!--                >-->

<!--                  <b-pagination-->
<!--                    v-model="currentPage"-->
<!--                    :total-rows="totalGuides"-->
<!--                    :per-page="perPage"-->
<!--                    first-number-->
<!--                    last-number-->
<!--                    class="mb-0 mt-1 mt-sm-0"-->
<!--                    prev-class="prev-item"-->
<!--                    next-class="next-item"-->
<!--                  >-->
<!--                    <template #prev-text>-->
<!--                      <feather-icon-->
<!--                        icon="ChevronLeftIcon"-->
<!--                        size="18"-->
<!--                      />-->
<!--                    </template>-->
<!--                    <template #next-text>-->
<!--                      <feather-icon-->
<!--                        icon="ChevronRightIcon"-->
<!--                        size="18"-->
<!--                      />-->
<!--                    </template>-->
<!--                  </b-pagination>-->

<!--                </b-col>-->

<!--      </b-row>-->
<!--    </div>-->
  </b-card>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BTable, BTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import commissionHandler from '@/views/dramox/commission/commissionHandler'
import commissionStoreModule from '@/views/dramox/commission/commissionStoreModule'

export default {
  name: 'HomeBusiness',
  components: {
    BCard,
    BRow,
    BCol,
    // BFormInput,
    // BButton,
    BTable,
    // BPagination,
    BTooltip,
  },
  data() {
    return {
    }
  },
  setup() {
    const BUSINESS_APP_STORE_MODULE_NAME = 'app-commissions'

    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, commissionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME)
    })

    const {
      tableColumnsSellers,
      fetchSellers,
    } = commissionHandler()
    return {
      fetchSellers,
      tableColumnsSellers,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
