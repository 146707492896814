import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function commissionHandler() {
  // Use toast
  const toast = useToast()

  // const refCommissionListTable = ref(null)

  // Table Handlers
  const tableColumnsCommission = [
    { key: 'sale_code', label: 'Código', sortable: false },
    { key: 'sale_date', label: 'F.Venta', sortable: false },
    { key: 'subtotal', label: 'Subtotal', sortable: false },
    { key: 'igv', label: 'IGV', sortable: false },
    { key: 'total', label: 'Total', sortable: false },
    { key: 'commission', label: 'Comisión(%)', sortable: false },
    { key: 'actions' ,label: 'Acciones'},
  ]

  const tableColumnsSellers = [
    { key: 'name', label: 'Nombre', sortable: false },
    { key: 'lastname', label: 'Apellido', sortable: false },
    { key: 'actions', label: 'Acciones' },
  ]

  // const perPage = ref(10)
  // const totalCommissions = ref(0)
  // const currentPage = ref(1)
  const searchQuery = ref('')


  const fetchSellers = (ctx, callback) => {
    store
      .dispatch('app-commissions/fetchSellers')
      .then(response => {
        callback(response.data.payload.results)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching sellers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    // fetchCommissionForSeller,
    fetchSellers,

    // commissionBySeller,

    tableColumnsCommission,
    tableColumnsSellers,
    // perPage,
    // currentPage,
    // totalCommissions,
    searchQuery,
    // refCommissionListTable,
    // refetchData,
  }
}
