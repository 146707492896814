import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCommissionForSeller(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/seller/${id}/commissions`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSellers(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/sellers')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSellerCommission(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/seller/commission', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
